<template>
    <v-container grid-list-xs class="centrado">
        <div style="text-align: center;">
            <img class="errorImage" src="../assets/errorDog1.jpg">
            <h3 class="errorTitle">No tienes permiso para ver este widget</h3>
        </div>
    </v-container>
</template>

<script>
export default {
}
</script>

<style>
.centrado{
    height: 100%; display: flex;
    justify-content: center;
    align-items: center;
}
.errorImage{
    width: 35%;
}
.errorTitle{
    text-align: center;
    color: white;
    text-shadow: 1px 1px black;
}
</style>