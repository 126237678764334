<template>
  <v-container class="mt-2">
    <v-row
      class="text-center"
      style="text-align: center !important; justify-content: center"
    >
      <v-col cols="9">
        <v-dialog
          v-model="showProgress"
          persistent
          :overlay="false"
          max-width="300px"
          max-height="300px"
          transition="dialog-transition-top"
        >
          <v-card style="text-align: center">
            <v-card-title primary-title> Cargando </v-card-title>
            <v-divider></v-divider>
            <div class="ma-4">
              <v-progress-circular
                :size="50"
                indeterminate
                color="blue"
                v-if="showProgress"
              ></v-progress-circular>
            </div>
            <v-divider></v-divider>
            <v-card-actions class="justify-end">
              <h6>Espere...</h6>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-card>
          <v-card-title primary-title> Project Settings </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container grid-list-xs style="padding-top: 0">
              <v-simple-table fixed-header height="225px">
                <template>
                  <thead>
                    <tr>
                      <th class="text-left headerTable">
                        Zoho Sprints (Projects)
                      </th>
                      <th>WebWork Tracker (Projects)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in tableData"
                      :key="index"
                      @click="clickRow(item)"
                    >
                      <td>{{ item.sprint[0].nameProject }}</td>
                      <td>{{ item.webwork[0].nameProject }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-container>
            <v-divider></v-divider>
            <v-card>
              <v-card-title primary-title> Match projects </v-card-title>
              <v-divider></v-divider>
              <v-row class="mb-3">
                <v-col class="ma-5">
                  <v-combobox
                    style="font-size: small"
                    v-model="projectNameSelected"
                    @change="sortWWProjects(projectNameSelected)"
                    label="Project Name"
                    :items="importantLinks"
                    outlined
                    dense
                  ></v-combobox>
                </v-col>
                <v-col class="mt-3 mr-4">
                  <v-list style="max-height: 200px" class="overflow-y-auto">
                    <v-list-item-group active-class="blue--text" multiple>
                      <template v-for="(item, index) in ecosystem">
                        <v-list-item :key="item.id" @click="addList(item)">
                          <template>
                            <v-list-item-title v-text="item.text">
                            </v-list-item-title>
                          </template>
                        </v-list-item>
                        <v-divider
                          v-if="index < ecosystem.length - 1"
                          :key="index"
                        ></v-divider>
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-col>
              </v-row>
            </v-card>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end noPadding">
            <!-- <v-btn class="btnSave" color="success" @click="dialogItems(projectName,selectedProjects)"><v-icon class="sizeIcon">mdi-content-save</v-icon></v-btn> -->
            <v-btn
              class="btnSave"
              color="success"
              @click="matchProjects(projectNameSelected, selectedProjects)"
              ><v-icon class="sizeIcon">mdi-content-save</v-icon></v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      transition="dialog-top-transition"
      max-width="700px"
      v-model="showDialog"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar color="blue" dark
            >Items/Tasks by Project: {{ projectName.text }}</v-toolbar
          >
          <v-card-text>
            <v-row>
              <v-col>
                <v-list style="max-height: 250px" class="overflow-y-auto">
                  <v-list-item-group active-class="blue--text" multiple>
                    <template v-for="(item, index) in ecosystem">
                      <v-list-item
                        :key="item.text"
                        @click="addList(item.text)"
                        class="itemList"
                      >
                        <template>
                          <v-list-item-title v-text="item.text">
                          </v-list-item-title>
                        </template>
                      </v-list-item>
                      <v-divider
                        v-if="index < ecosystem.length - 1"
                        :key="index"
                      ></v-divider>
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-col>
              <v-col>
                <v-list style="max-height: 250px" class="overflow-y-auto">
                  <v-list-item-group active-class="blue--text" multiple>
                    <template v-for="(item, index) in ecosystem">
                      <v-list-item :key="item.id" @click="addList(item)">
                        <template>
                          <v-list-item-title v-text="item.text">
                          </v-list-item-title>
                        </template>
                      </v-list-item>
                      <v-divider
                        v-if="index < ecosystem.length - 1"
                        :key="index"
                      ></v-divider>
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="dialog.value = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog
      transition="dialog-top-transition"
      fullscreen
      v-model="dialogTasks"
    >
      <template v-slot:default="dialog">
        <v-card tile>
          <v-toolbar color="blue" dark flat>
            <v-btn class="ml-1" icon dark @click="dialog.value = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <div class="ml-5">
              <p style="margin-bottom: 0px !important">
                Items/Tasks by Project: <b>{{ projectName }}</b>
              </p>
            </div>
            <v-spacer></v-spacer>
            <v-btn text @click="openAddTasks()"
              ><v-icon class="sizeIcon">mdi-plus-circle-outline</v-icon></v-btn
            >
            <v-btn text @click="saveLinks(dialog, data.links)">Save</v-btn>
          </v-toolbar>
          <div>
            <SimpleFlow :scene.sync="data" />
          </div>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="dialogAddTasks"
    >
      <template>
        <v-card>
          <v-toolbar color="blue" dark class="text-h5">Create tasks</v-toolbar>
          <v-card-text>
            <div class="mt-5">
              <v-combobox
                :items="data.nodes"
                item-text="label"
                v-model="comboBox"
                label="Create webwork tasks from Sprints items:"
                multiple
                outlined
                hide-details
              >
                <template v-slot:selection="data">
                  <v-chip
                    :key="JSON.stringify(data.label)"
                    v-bind="data.attrs"
                    :input-value="comboBox"
                    :disabled="data.disabled"
                  >
                    <v-avatar
                      class="blue white--text"
                      left
                      v-text="data.item.label.slice(0, 1).toUpperCase()"
                    ></v-avatar>
                    {{ data.item.label }}
                  </v-chip>
                </template>
              </v-combobox>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn color="success" @click="addTasks(comboBox)"
              >Create tasks</v-btn
            >
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <Dialog
      :dialog-model="dataDialog.dialogModel"
      :dialog-title="dataDialog.dialogTitle"
      :dialog-message="dataDialog.dialogMessage"
    />
  </v-container>
</template>
<style>
.roundAdd {
  border-radius: 100%;
}
.floatBox {
  width: 50% !important;
  position: absolute !important;
  margin-top: 33% !important;
  margin-left: 49% !important;
  border-radius: 10px !important;
}
.itemsCol {
  flex-grow: 0 !important;
}
.nodeLeft {
  width: 15px;
  margin-bottom: 10px;
  margin-left: 15px;
  border-radius: 50%;
  height: 15px;
  border-inline-color: color;
  border-radius: black 100%;
  border: black 1px solid;
}
.nodeRight {
  width: 15px;
  margin-bottom: 10px;
  margin-right: 15px;
  border-radius: 50%;
  height: 15px;
  border-inline-color: color;
  border-radius: black 100%;
  border: black 1px solid;
}
.nodeLeft:hover,
.nodeRight:hover {
  background: rgb(232, 15, 15);
  cursor: pointer;
}
td,
th {
  text-align: left;
}
.btnSave {
  position: absolute !important;
  margin-top: -50px !important;
  border-radius: 100% !important;
  height: 60px !important;
}
.sizeIcon {
  font-size: 2rem !important;
}
.noPadding {
  padding: 0px !important;
}
</style>
<script>
import SimpleFlow from "flowchartqa";
import "flowchartqa/dist/vue-flowchart.css";
import Dialog from "./dialogs/generalDialog.vue";
const axios = require("axios");
const stringSimilarity = require("string-similarity");

export default {
  components: {
    SimpleFlow,
    Dialog,
  },
  mounted() {
    var idManager = this.$route.params.id;
    this.getProyectWebWork();
    this.getProyectZohoSprint();
    this.getMatchProjects(idManager);
  },
  methods: {
    async openAddTasks() {
      this.dialogAddTasks = true;
    },
    async addTasks(arrayItems) {
      this.showProgress = true;
      var addItem = [];
      for (const item in arrayItems) {
        if (Object.hasOwnProperty.call(arrayItems, item)) {
          const element = arrayItems[item];
          var idItem = element.id;
          var nameItem = element.label;
          addItem.push({
            id: idItem,
            name: nameItem,
          });
        }
      }
      await axios({
        url: "https://sprintsww.quickapps.mx/api/v1/task/addTask",
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: addItem,
      });
      this.showProgress = false;
      this.dialogTasks = false;
      this.comboBox = [];
    },
    async saveLinks(dialog, arrayLink) {
      this.showProgress = true;
      console.log(arrayLink);
      for (const item in arrayLink) {
        var webworkID = arrayLink[item].to;
        var sprintID = arrayLink[item].from;
        var itemTask = {
          itemID: sprintID,
          taskID: webworkID,
        };
        const result = await axios({
          url: "https://sprintsww.quickapps.mx/api/v1/task/itemsTasks",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: itemTask,
        });
        console.log(result);
      }
      dialog.value = false;
      this.showProgress = false;
    },
    async clickRow(item) {
      this.showProgress = true;
      this.sprintItems = [];
      this.webworkTasks = [];
      this.data.nodes = [];
      this.data.links = [];
      var sprintData = item.sprint[0];
      console.log("SprintData: ", sprintData);
      var webworkData = item.webwork[0];
      var dataID = {
        SProjectID: sprintData.id,
        WProjectID: webworkData.id,
      };
      this.projectName = sprintData.nameProject.concat(
        " / ",
        webworkData.nameProject
      );
      const httpSprintResult = await axios({
        url: "https://sprintsww.quickapps.mx/api/v1/task/zohosprint",
        method: "get",
        params: dataID,
      });
      var coordX = 200;
      var coordY = 30;
      if (httpSprintResult.data.items != undefined) {
        var auxItems = [];
        httpSprintResult.data.items.forEach((element) => {
          element.forEach((item) => {
            auxItems.push(item);
          });
        });
        auxItems.sort(function (a, b) {
          if (a.nameItem > b.nameItem) {
            return 1;
          }
          if (a.nameItem < b.nameItem) {
            return -1;
          }
          return 0;
        });
        console.log(auxItems);
        auxItems.forEach((items) => {
          this.data.nodes.push({
            id: items.id,
            x: coordX,
            y: coordY,
            type: "Zoho Sprints",
            label: items.nameItem,
          });
          this.sprintItems.push({
            id: items.id,
          });
          coordY += 80;
        });
        coordX = 1100;
        coordY = 30;
        httpSprintResult.data.task.sort(function (a, b) {
          if (a.title > b.title) {
            return 1;
          }
          if (a.title < b.title) {
            return -1;
          }
          return 0;
        });
        console.log(httpSprintResult.data.task);
        httpSprintResult.data.task.forEach((item) => {
          this.data.nodes.push({
            id: item.id,
            x: coordX,
            y: coordY,
            type: "Webwork Tracker",
            label: item.title,
          });
          this.webworkTasks.push({
            id: item.id,
          });
          coordY += 80;
        });
        var iterador = 1;
        for (const iterator of this.sprintItems) {
          const idItem = {
            id: iterator.id,
          };
          const httpResult = await axios({
            url: "https://sprintsww.quickapps.mx/api/v1/task/getItem",
            method: "get",
            params: idItem,
          });
          var idTask = httpResult.data.idTask;
          this.webworkTasks.forEach((element) => {
            var findTask = element.id;
            if (findTask == idTask) {
              this.data.links.push({
                id: iterador,
                to: findTask,
                from: idItem.id,
              });
              iterador++;
            }
          });
        }
        this.dialogTasks = true;
        this.showProgress = false;
      } else {
        this.dataDialog.dialogTitle = "Tareas no encontrados";
        this.dataDialog.dialogMessage =
          "Estos proyectos no se encuentran con tareas, por favor de agregar en las plataformas";
        this.dataDialog.dialogModel = true;
      }
    },
    getProyectWebWork: async function () {
      const httpResult = await axios({
        url: "https://sprintsww.quickapps.mx/api/v1/project/webwork",
        method: "get",
      });
      for (const project of httpResult.data) {
        this.ecosystem.push({
          text: project.project_name.toLowerCase(),
          id: project.project_id,
        });
      }
    },
    getProyectZohoSprint: async function () {
      const httpResult = await axios({
        url: `https://sprintsww.quickapps.mx/api/v1/project/zohosprint`,
        method: "get",
      });
      for (const project in httpResult.data) {
        this.importantLinks.push({
          text: httpResult.data[project]["nameProject"].toLowerCase(),
          id: httpResult.data[project]["idProject"],
          match: 0,
        });
      }
    },
    getMatchProjects: async function (value) {
      var flag = false;
      var arrayAllAccess = [
        51751000000741233n,
        51751000000741217n,
        51751000000741209n,
        51751000000020051n,
        51751000000186093n,
        51751000000006001n,
        51751000000002003n,
      ];
      arrayAllAccess.forEach((element) => {
        if (element == value) {
          flag = true;
        }
      });
      if (flag) {
        console.log("All Access");
        const httpResult = await axios.get(
          "https://sprintsww.quickapps.mx/api/v1/project/sprintwebworkall",
          {}
        );
        console.log("result: ", httpResult);
        this.tableData = httpResult.data;
      } else {
        console.log("Caution Access");
        const httpResult = await axios.get(
          "https://sprintsww.quickapps.mx/api/v1/project/sprintwebwork",
          {
            params: { id: value },
          }
        );
        this.tableData = httpResult.data;
      }
    },
    sortWWProjects(name) {
      this.selectedProjects = [];
      let spirntPJ = name.text;
      const array = this.ecosystem.map((elemento) => elemento.text);
      const arrayTItle = [];
      for (const obj of array) {
        if (!arrayTItle.includes(obj)) {
          arrayTItle.push(obj);
        }
      }
      let matches = stringSimilarity.findBestMatch(spirntPJ, arrayTItle);
      let unsorted = matches.ratings;
      let sorted = unsorted.sort(function (a, b) {
        return b.rating - a.rating;
      });
      let objeto = null;
      let list = [];
      for (let j = 0; j < sorted.length; j++) {
        objeto = this.ecosystem.find(
          (elemento) => elemento.text == sorted[j].target
        );
        list.push({ text: objeto.text, id: objeto.id });
      }
      while (this.ecosystem.length > 0) {
        this.ecosystem.pop();
      }
      for (const obj of list) {
        this.ecosystem.push(obj);
      }
    },
    dialogItems(projectName, selectedProjects) {
      if (projectName.text !== "" && selectedProjects !== []) {
        this.showDialog = true;
      }
    },
    async matchProjects(projectZohoSprint, selectedProjects) {
      //realizar  match en la base de datos
      console.log("zoho: ", projectZohoSprint);
      console.log("selected: ", selectedProjects);
      this.showProgress = true;
      const idProjectsWebWork = selectedProjects.map((element) => element.id);
      const jsonData = {
        idProjectZohoSprint: projectZohoSprint.id,
        idProjectsWebWork: idProjectsWebWork,
      };
      const result = await axios({
        url: `https://sprintsww.quickapps.mx/api/v1/project/match`,
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: jsonData,
      });
      if (result.data == "Encontrado") {
        this.showProgress = false;
        this.dataDialog.dialogTitle = "Emparejamiento encontrado";
        this.dataDialog.dialogMessage =
          "Estos proyectos ya se encuentran emparejados, intente con otro";
        this.dataDialog.dialogModel = true;
      } else {
        this.showProgress = false;
        window.location.reload();
      }
      //const resultHttp = await axios.post('https://sprintsww.quickapps.mx/api/v1/project/match', jsonData );
    },
    addList(item) {
      if (this.selectedProjects.length === 0) {
        this.selectedProjects.push(item);
      } else {
        var index = 0;
        var temp = 0;
        var flag = false;
        this.selectedProjects.forEach((element) => {
          if (element === item) {
            index = temp;
            flag = true;
          } else {
            temp++;
          }
        });
        if (flag) {
          this.selectedProjects.splice(index, 1);
        } else {
          this.selectedProjects.push(item);
        }
      }
    },
  },
  data: () => ({
    data: {
      centerX: 0,
      centerY: 0,
      scale: 0.7,
      nodes: [
        /* {
          id: 1,
          x: 1,
          y: 1,
          type: 'test',
          label: 'test'
        } */
      ],
      links: [
        /* {
          id: 2,
          to: 1,
          from: 1
        } */
      ],
    },
    dataDialog: {
      dialogModel: false,
      dialogTitle: "",
      dialogMessage: "",
    },
    dialogAddTasks: false,
    dialogTasks: false,
    showDialog: false,
    showProgress: false,
    projectName: "",
    projectNameSelected: "",
    projectidSelected: 0,
    selectedProjects: [],
    ecosystem: [],
    importantLinks: [],
    tableData: [],
    sprintItems: [],
    webworkTasks: [],
    comboBox: [],
    nodesSprint: [],
    nodesWebwork: [],
    links: [],
  }),
};
</script>