<template>
    <v-app>
        <v-main class="background center">
          <div v-if="flag">
            <v-responsive>
              <router-view/>
            </v-responsive>
          </div>
          <div v-else class="background center">
            <Error/>
          </div>
        </v-main>
    </v-app>
</template>
<style> 
.background{
  background-image: url('assets/background.png');
  width: 100%;
  height: 100%;
}
.center{
  place-self: center;
}
</style>

<script>
const axios =    require("axios"); //import
import Error from './views/Error.vue'
export default {
  components:{
    Error
  },
  mounted(){
    this.getProjectManagers()
  },
  methods:{
    getProjectManagers : async function(){
      const httpResult = await axios({
        url: "https://sprintsww.quickapps.mx/api/v1/user/getManagers",
        method: "get"
      })
      var getData = httpResult.data
      getData.forEach(element => {
        var idManager = element.idUser
        this.idsProjectManagers.push(idManager)
      })
      if(this.idsProjectManagers.includes(this.$route.params.id) ){
        this.flag = true;
      }else{
        this.flag = false;
      }
    },
  },
  data: () => ({
    idsProjectManagers:[],
    flag: true,
  })
}
</script>
