<template>
    <Error/>
</template>

<script>
import Error from '../components/ErrorSettings.vue'
export default {
    components:{
        Error
    }
}
</script>

<style>

</style>