<template>
  <ProjectSettings/>
</template>

<script>
import ProjectSettings from '../components/ProjectSettings'

export default {
  name: 'Home',

  components: {
    ProjectSettings
  }
}
</script>
