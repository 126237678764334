<template>
  <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="dialogModel">
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar
            color="error"
            dark
            class="text-h4"
          >{{dialogTitle}}</v-toolbar>
          <v-card-text>
            <div class="text-h6 pa-12">{{dialogMessage}}</div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn
              text
              @click="reload(dialog.value)"
            >Close</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
</template>

<script>
export default {
    props: {
        dialogModel: Boolean,
        dialogTitle: String,
        dialogMessage: String,
    },
    methods: {
      reload(value){
        value = false
        window.location.reload()
      }
    }
}
</script>

<style>

</style>